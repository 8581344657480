.radioGroupRow {
  display: flex;
  flex-direction: row;
}

.tableRow {
  padding: 12px 16px;
}

.tableHeader {
  position: relative;
  padding: 32px 5px;
  padding-bottom: 0px;
  font-size: 24px;
  line-height: 24px;
  color: #212b36;
  font-weight: normal;
  text-align: center;
  border: 1px solid transparent;
  border-bottom: 0;
}

.tableHeaderSelected {
  position: relative;
  padding: 32px 5px;
  padding-bottom: 0px;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #212b36;
  font-weight: normal;
  background-color: #f5faff;
  border: 1px solid #95cffc;
  border-bottom: 0;
}

.tableRowColumn {
  width: 14%;
  vertical-align: middle;
  font-size: 14px;
  height: 25px;
  text-align: center;
  padding: 10px 0px;
  border-bottom: 1px solid #dfe3e8;
}

.tableRowColumnSelected {
  width: 14%;
  vertical-align: middle;
  font-size: 14px;
  height: 25px;
  text-align: center;
  padding: 5px 0px;
  background-color: #f5faff;
  border-color: #dfe3e8;
  border-left: 1px solid #95cffc;
  border-right: 1px solid #95cffc;
}

.tableRowColumnSelectedLast {
  width: 14%;
  vertical-align: middle;
  font-size: 14px;
  height: 25px;
  text-align: center;
  padding: 5px 0px;
  background-color: #f5faff;
  border-color: #dfe3e8;
  border-right: 1px solid #95cffc;
  border-left: 1px solid #95cffc;
  border-bottom: 1px solid #95cffc;
}

.tableRowColumnSelectedFirst {
  width: 14%;
  vertical-align: middle;
  font-size: 14px;
  height: 25px;
  text-align: center;
  padding: 5px 0px;
  background-color: #f5faff;
  border-color: #dfe3e8;
  border-right: 1px solid #95cffc;
  border-left: 1px solid #95cffc;
}

.tableRowColumnLeft {
  width: 30%;
  padding: 5px 16px;
  vertical-align: middle;
  font-size: 14px;
  text-align: left;
  white-space: normal;
  border-bottom: 1px solid #dfe3e8;
}

.formItemLeft {
  margin-top: 15px;
  float: left;
}

.textInputMultiLine {
  padding: 0px 12px;
  border: 1px solid #e0e0e0;
}

.mostPopular {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 20px;
  top: 0px;
  left: 0;
  font-size: 0.6rem;
  text-transform: uppercase;
  background-color: rgb(250, 219, 93);
  border-radius: 0px 0px 5px 5px;
  color: #000;
}

.planSelected {
  font-size: 12px;
  height: 25px;
  text-align: center;
  padding: 17px 5px;
  background-color: #f5faff;
  border-color: #dfe3e8;
  border-left: 1px solid #95cffc;
  border-right: 1px solid #95cffc;
}

.planNotSelected {
  font-size: 12px;
  height: 25px;
  text-align: center;
  padding: 17px 5px;
}

.priceWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 12px;
  gap: 4px;
  &__currency {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #121723;
    align-self: flex-start;
  }
  &__price {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    color: #2e2e2e;
    align-self: flex-end;
  }
  &__month {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #7d7f86;
    align-self: flex-end;
  }
  &__economy {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #c4c5c8;
    text-decoration-line: line-through;
    align-self: flex-end;
  }
}

.tableWrap {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  td {
    min-width: 200px;
  }
}

.scrollable-container {
  overflow-x: auto;
}

.creditsWrap {
  display: flex;
  gap: 18px;
  min-width: 1240px;
  margin: 10px 0px;
  .Polaris-Text--headingMd{
    font-size: 16px !important;
  }
  .Polaris-Text--heading2xl{
    font-size: 28px;
  }
  .Polaris-Text--bodySm{
    color: #6d7175 !important;
  }
  .Polaris-Text--bodyMd{
    color: #008060 !important;
  }

}
