.declarationMethodWrapper {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.declarationMethod {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid transparent;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-card);
  border-radius: 10px;
  width: 50%;
  cursor: pointer;
  transition: 0.1s ease;
  &:hover {
    background-color: #f5faff;
    border: 1px solid #95cffc;
  }
}

.declarationMethod.active {
  background-color: #f5faff;
  border: 1px solid #95cffc;
}
.button-upload-options-style{
  width: 200px;
  height: 44px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(201, 204, 207, 1);
  border-radius: 4px;
  cursor: pointer;
}

.epr-declaration_create{
  .Polaris-DataTable__Cell .Polaris-Box {
    flex-direction: column !important;
  }
}