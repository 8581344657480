.card {
  width: fit-content;
  padding: 1rem 2rem;
  border: 2px solid rgba(190, 225, 252, 1);
  border-radius: 8px;
  margin-top: 20px;
}

.subscription-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}
