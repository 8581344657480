.userGuide {
  &-progressBarWrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    &-progressBar {
      width: 100%;
      height: 20px;
      background: #daf1f2;
      border-radius: 2px;
      &-line {
        background: #59c7c5;
        border-radius: 2px 0px 0px 2px;
        height: 100%;
      }
    }
  }
}
