.epr-modal_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: auto;
}

.epr-modal_list {
  list-style-type: none;
  min-width: 220px;
}

.Polaris-Box {
  white-space: normal !important;
  display: flex;
  flex-direction: column;
}

/*# sourceMappingURL=modalStyle.css.map */
