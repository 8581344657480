.support {
  &__managerWrap {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;

    @media screen and (max-width: 1024px) {
      align-items: center;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
.supportMessages{
  .Polaris-Page--fullWidth{
    padding: 0;
  }
}