.loginWrap {
  position: relative;
  z-index: 100;

  .parrotImg {
    position: absolute;
    top: 50%;
    right: 20%;
    z-index: -1;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
