.dash-chart-container {
  height: 180px;
  width: 180px;
  margin: auto;
}
.sales-tax-returns {
  margin: 6px 0;
  .Polaris-Card__Section{
    padding: 0;
  }
}
.epr-returns {
  margin: 0 0 15px 0;
  .Polaris-Card__Section{
    padding: 0;
  }
  .Polaris-DataTable__Cell--verticalAlignTop {
    &:last-child {
      text-align: right;
    }
  }
}
.table-dashboard-returns{
  .Polaris-DataTable__Cell {
    width: 37% !important;
  }
}