.sendEinvoices {
  &-content{
    margin-top: 20px;
  }
  &-wrapper{
    width: 100%;
    padding: 30px;
    &_multiple{
      max-height: 600px;
      overflow: auto;
      .Polaris-DataTable--condensed{
        width: 100%;
        height: 100%;
        overflow: auto;
      }
    }
  }
}
.changeLineInTableColor{
  display: block;
  position: relative;
}
