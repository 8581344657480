.message-count {
  display: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #1aa2ff;
  border-radius: 100%;
}

.message-count.active {
  display: flex;
}
