.topbar {
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 5;
  margin-top: 0;
  background-color: rgb(255, 255, 255);
}
@media screen and (max-width: 768px) {
  .topbar {
    position: relative;
  }
}
.topbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .topbar-wrapper {
    justify-content: center;
  }
}
.topbar-wrapper_block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
@media screen and (max-width: 768px) {
  .topbar-wrapper_block {
    justify-content: center;
  }
}
.topbar-wrapper_block-dot {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 3;
}
.topbar-wrapper_block-avatar {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
.topbar-wrapper_block-menu {
  width: 250px;
  position: fixed;
  background-color: rgb(255, 255, 255);
  top: 60px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 5px 1rem rgba(41, 47, 54, 0.6);
  padding: 20px 0;
  animation: forwards 0.7s AnimationShowMenu ease;
  z-index: 200;
}
.topbar-wrapper_block-menu_content {
  width: inherit;
  padding: 0;
  margin: 0;
  animation: forwards 1.4s AnimationShowContent ease;
}
.topbar-wrapper_block-menu_title {
  font-size: 16px;
  color: rgb(102, 112, 133);
  padding: 10px;
  font-weight: lighter;
}
.topbar-wrapper_block-menu_logo {
  height: 40px;
  animation: forwards 3s AnimationShowContent ease;
}
.topbar-wrapper_block-menu_body {
  width: inherit;
  padding: 0;
  margin: 0;
}
.topbar-wrapper_block-menu_body-item {
  width: 100%;
  height: 40px;
  cursor: pointer;
  border-bottom: 2px solid rgba(102, 112, 133, 0.1);
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}
.topbar-wrapper_block-menu_body-item:hover .topbar-wrapper_block-menu_body-item_link {
  color: rgb(32, 34, 35);
}
.topbar-wrapper_block-menu_body-item:hover .topbar-wrapper_block-menu_body-item_link-icon {
  fill: rgb(32, 34, 35);
}
.topbar-wrapper_block-menu_body-item:hover .topbar-wrapper_block-menu_body-item_link-icon_arrow {
  opacity: 1;
  animation: forwards 0.5s linear AnimationArrowRight;
}
.topbar-wrapper_block-menu_body-item_link {
  font-weight: 600;
  color: rgb(102, 112, 133);
  text-decoration: none;
}
.topbar-wrapper_block-menu_body-item_link-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  fill: rgb(102, 112, 133);
}
.topbar-wrapper_block-menu_body-item_link-icon_arrow {
  width: 15px;
  height: 15px;
  margin-left: 30px;
  opacity: 0;
  fill: rgb(102, 112, 133);
}
.topbar-wrapper_title {
  font-size: calc(14pxpx + (20px - 14px) * (100vw - 320px) / 1120);
  color: rgb(32, 34, 35);
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .topbar-wrapper_title {
    text-align: center;
  }
}
.topbar-wrapper_checkboxs {
  height: inherit;
  display: flex;
  align-items: center;
}
.topbar-wrapper_checkboxs-item {
  color: rgb(32, 34, 35);
  font-size: 18px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topbar-wrapper_checkboxs-item .form-check-input {
  margin-bottom: 5px;
  margin-right: 5px;
}
.topbar-wrapper_checkboxs-item * {
  cursor: pointer;
}
.topbar-wrapper_typeahead {
  width: 200px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .topbar-wrapper_typeahead {
    margin-left: 0;
  }
}

.active-dots_icon:after {
  content: "";
  width: 40px;
  height: 40px;
  background-color: rgba(102, 112, 133, 0.1);
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: -10px;
  z-index: 2;
}

@keyframes AnimationShowMenu {
  0% {
    height: 0;
  }
  100% {
    height: 350px;
  }
}
@keyframes AnimationShowContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes AnimationArrowRight {
  0% {
    margin-left: 3px;
    opacity: 0;
  }
  100% {
    margin-left: 30px;
    opacity: 1;
    fill: rgb(32, 34, 35);
  }
}

/*# sourceMappingURL=topbar.css.map */
