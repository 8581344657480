.ReactModal__Overlay {
  .guideCompletionModal {
    opacity: 0;
    right: -300px;
  }
}

.ReactModal__Overlay--after-open {
  .guideCompletionModal {
    opacity: 1;
    right: 48px;
  }
}

.ReactModal__Overlay--before-close {
  .guideCompletionModal {
    opacity: 0;
    right: -300px;
  }
}

.guideCompletionModal {
  position: absolute;
  top: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background: #ffffff;
  padding: 24px;
  outline: none;
  transition: all 100ms ease-in-out;

  &__close {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
  }
}
