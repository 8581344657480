.segmented {
  width: 100%;
  height: 45px;
  display: flex;
  background-color: var(--p-background-pressed);
  border-radius: var(--p-border-radius-2);

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid var(--p-background-pressed);
    border-radius: var(--p-border-radius-2);
    transition: 0.1s ease;
    cursor: pointer;
  }

  &-button:not(.active) {
    background-color: var(--p-background-pressed);
    button {
      color: black;
    }
  }

  &-button.active,
  &-button:hover {
    background-color: var(--p-surface);
  }
}
