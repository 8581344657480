.customPagination.rc-pagination {
  display: flex;
  align-items: center;
  margin-right: 3rem;
  li {
    width: 32px;
    height: 32px;

    background: #eeeeee;
    border-radius: 4px;
    border: 0;
    line-height: 32px;
    font-weight: 600;
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    background: transparent;
  }

  .rc-pagination-prev {
    margin-top: 8px;
  }

  .rc-pagination-next {
    transform: rotate(180deg);
    margin-bottom: 8px;
  }

  .rc-pagination-item-active {
    background: rgba(38, 127, 215, 1);
    a {
      color: #fff;
    }
  }

  .rc-pagination-total-text {
    display: flex;
    width: fit-content;
    margin-right: auto;
    color: #858585;
    background-color: transparent;
  }

  .rc-pagination-options {
    display: none;
  }
}
