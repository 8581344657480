$gap: var(--p-space-3);
$totals-width: 50%;
$totals-tax-width: 100%;
$totals-table-total-min-width: 100px;

.totals {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 0;
  gap: $gap;
  width: $totals-width;

  &-tax {
    display: flex;
    gap: $gap;
    width: $totals-tax-width;

    &-select {
      flex: 1;
    }
  }

  &-selectedTax {
    width: $totals-tax-width;
  }

  &-numbers {
    display: flex;
    flex-direction: column;
    gap: $gap;
    width: $totals-tax-width;
  }

  &-table {
    &-total {
      min-width: $totals-table-total-min-width;
    }
  }
}

.custom-listbox {
  max-height: 250px !important;
  overflow-y: auto;
  padding: 5px;
}
