.lookup-wrapper {
  width: fit-content;
  margin: 0 auto;
}
.lookup-wrapper_search {
  display: flex;
  align-content: flex-start;
  justify-content: center;
  background: rgb(228, 245, 254);
  background: linear-gradient(90deg, rgba(228, 245, 254, 0.2) 0%, rgba(92, 137, 196, 0.3) 50%, rgba(228, 245, 254, 0.2) 100%);
  border-radius: 6px;
  padding: 50px;
}
.lookup-wrapper_search-top {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lookup-wrapper_search-label {
  width: inherit;
}
.lookup-wrapper_search-text {
  margin: 20px 0 !important;
  color: rgb(32, 34, 35);
  font-weight: 500;
  padding: 0 10px;
  text-align: center;
}
.lookup-wrapper_search-button {
  width: inherit;
}
.lookup-wrapper_search-button_icon {
  width: 20px;
  height: 20px;
  fill: rgb(255, 255, 255);
  margin-right: 10px;
}
.lookup-wrapper_error {
  font-size: 20px;
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  color: rgb(255, 99, 71);
}
.lookup-wrapper_container {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  height: 70vh;
  overflow-x: auto;
  margin-top: 30px;
  padding-bottom: 30px;
}
.lookup-wrapper_container-block {
  width: 500px;
  height: 180px;
  box-shadow: 0 5px 1rem rgba(182, 195, 211, 0.8);
  border-radius: 6px;
  padding: 20px;
  margin: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.lookup-wrapper_container-block:nth-child(even) {
  background: rgb(228, 245, 254);
  background: linear-gradient(90deg, rgba(228, 245, 254, 0.1) 0%, rgba(92, 137, 196, 0.1) 50%, rgba(228, 245, 254, 0.1) 100%);
}
.lookup-wrapper_container-block:hover {
  cursor: pointer;
  background: linear-gradient(90deg, rgba(228, 245, 254, 0.3) 0%, rgba(92, 137, 196, 0.3) 75%, rgba(228, 245, 254, 0.3) 100%);
}
.lookup-wrapper_container-block:hover * {
  cursor: pointer;
}
.lookup-wrapper_container-block_id, .lookup-wrapper_container-block_country, .lookup-wrapper_container-block_name, .lookup-wrapper_container-block_date {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
}
.lookup-wrapper_container-block_id-title, .lookup-wrapper_container-block_country-title, .lookup-wrapper_container-block_name-title, .lookup-wrapper_container-block_date-title {
  white-space: nowrap;
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
}
.lookup-wrapper_container-block_id-value, .lookup-wrapper_container-block_country-value, .lookup-wrapper_container-block_name-value, .lookup-wrapper_container-block_date-value {
  font-size: 14px;
}
.lookup-wrapper_container-block_button {
  margin-top: 20px !important;
}
.lookup-wrapper_container-block_button-icon {
  width: 20px;
  height: 20px;
  fill: rgb(255, 255, 255);
  margin-right: 10px;
}

/*# sourceMappingURL=style.css.map */
