.services-in-development-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 70px;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-card);
  outline: var(--p-border-width-1) solid transparent;
  border-radius: var(--p-border-radius-2);
  img {
    width: 100%;
    height: auto;
  }
}
