.changeLineInTableColor {
  min-height: 10vh;
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 12px;
  padding-bottom: 12px;
  td{
    padding: 0;
  }
  &-ischecked{
    padding: 13.6px;
  }
}