.accordion {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;

  &__wrapper {
    cursor: pointer;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 1rem;
  }

  &__description {
    padding: 0 1rem;
    padding-bottom: 1rem;
  }

  &__content {
    border-top: 1px solid #eeeeee;
    padding: 1rem;
  }
}
