.cancelSubscriptionBuilder {
  &-Polaris {
    width: 600px !important;
    //width: 100%;
  }

  &-wrapperButtons {
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 30px;
    border-top: 1px solid rgba(46, 46, 46, 0.2);
    button {
      width: 220px;
    }

    &_block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .Polaris-Button {
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }

  &-cards {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
    padding: 20px 0;

    &_item {
      margin: 0 10px;

      .Polaris-Card {
        min-width: 320px;
        min-height: 450px;
        background-color: rgba(224, 224, 224, .2);
      }

      &-label {
        .Polaris-Choice__Label {
          font-size: 20px;
          font-weight: bold;
          text-wrap: nowrap;
        }
      }

      &-addons {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        border-radius: 8px;
        &_line{
          display: flex;
          min-width: 300px;
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }

  &-button {
    position: relative;

    &_back {
      width: 120px;
    }

    &_account {
      position: absolute;
      left: 30px;

      .Polaris-Button {
        text-decoration: none !important;
      }
    }
  }

  &-progress_bar {
    height: 2px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 16px;
    overflow: hidden;

    &-progress_line {
      height: 100%;
      background-color: #0070f3;
      transition: width 0.3s ease;
    }
  }

  &-description {
    //min-height: 720px;
    margin: 30px auto;
    padding: 20px 50px;

    &_checkboxes {
      .Polaris-Choice__Label {
        font-size: 18px;
        margin-bottom: 15px;
        line-height: 25px;
      }

      .Polaris-ChoiceList__Choices {
        padding-left: 30px;
        margin-top: 30px;
      }
    }

    &_p, &_subtitle {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 25px;
    }

    &_topTitle {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 25px;
    }

    &_title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &_cancel {
      margin: 30px 0;
      padding: 16px;
    }

    &_refund {
      background-color: rgba(246, 246, 247, 1);
      border-radius: 8px;
      padding: 16px;
    }

    &_link {
      padding-left: 3px;
      color: black;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600;
    }

    &_bull {
      font-weight: bold;
      font-size: 20px;
      margin-right: 10px;
    }
  }
}

.three-step{
  &_item {
    margin: 0 10px;
    .Polaris-Card {
      min-width: 320px;
      min-height: max-content;
      background-color: rgba(224, 224, 224, .1);
    }
  }
}