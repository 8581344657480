.HighlightableDataTable {
  .Polaris-DataTable__Cell {
    vertical-align: middle;
  }
  .Polaris-DataTable--hoverable:hover {
    td {
      background-color: var(--p-surface-hovered);
    }
    .ButtonOnHover {
      opacity: 1;
    }
  }
  .Polaris-DataTable--hoverable.bg-red,
  .Polaris-DataTable--hoverable.bg-yellow:hover {
    td {
      background-color: initial;
    }
  }
}
