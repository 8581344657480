.SaveBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  height: 72px;
  position: fixed;
  bottom: 0;
  //right: var(--p-space-600);
  left: 50%;
  transform: translateX(calc(-50% + 140px));
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.15);
  border-radius: var(--p-border-radius-200) var(--p-border-radius-200) 0 0;
  z-index: var(--p-z-index-5);
  transition: var(--p-motion-duration-500);

  @media screen and (max-width: 1027px) {
    width: 72%;
  }

  @media (max-width: 48em) {
    left: var(--p-space-600);
    right: var(--p-space-600);
    width: auto;
  }
}

.SaveBar.fullWidth {
  position: absolute;
  width: calc(100% - 2 * var(--p-space-600));
}

.drawer-close + .content-container .SaveBar {
  padding: var(--p-space-200);
  transition: var(--p-motion-duration-500);
}
