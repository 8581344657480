.changeCompany {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &-option {
    &-body {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 16px;
      border: 1px solid var(--grey-50, #eee);
      border-radius: 8px;

      margin-top: 6px;

      cursor: pointer;

      &-text {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      &-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &:hover {
        background-color: #f5faff;
      }
    }
    .active &-body {
      background-color: #fbfbfb;

      cursor: auto;
    }
  }
}
