.FAQ {
  &__category {
    width: 100%;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    height: 60px;
    padding: 0px 1rem;
    gap: 1rem;

    cursor: pointer;
    transition: 0.1s ease;
    &-arrows {
      margin-left: auto;
    }
  }

  &__category.active,
  &__category:hover {
    border-color: var(--p-action-primary);
    .FAQ__category-arrows {
      path {
        stroke: var(--p-action-primary);
      }
    }
  }
}
