.platformItemsWrap {
  display: grid;
  grid-template-columns: repeat(2, 220px);
  gap: 1rem;
  position: relative;
  &-button {
    position: absolute;
    right: 0px;
    top: -40px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(140, 145, 150, 1);
    cursor: pointer;
    *cursor: pointer;
  }
}

.platformItem {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  cursor: pointer;

  img {
    width: 100%;
    height: 110px;
    object-fit: contain;
  }
}

.platformItem.active {
  border: 1px solid rgba(38, 127, 215, 1);
}
