.dialog {
  &__messages {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 60vh;
    overflow-y: auto;
    padding: 0px 10px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #eeeeee;
      border-radius: 8px;
    }
  }
  &__message {
    display: flex;
    gap: 1rem;
    align-items: start;
    width: fit-content;
    max-width: 75%;
    &-text {
      width: fit-content;
      padding: 10px;
      padding-right: 16px;
      background: #eeeeee;
      border-radius: 0px 8px 8px 8px;
      margin-top: 6px;
    }
    &-date {
      margin-left: 3rem;
    }
    &-file {
      width: 250px;
      padding: 10px 0px;
      display: flex;
      gap: 0.5rem;
      a {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  &__message.myMessage {
    align-self: flex-end;
    .dialog__message-text {
      background: #bee1fc;
      border-radius: 8px 0px 8px 8px;
      margin-left: auto;
    }
    .dialog__message-file {
      margin-left: auto;
    }
  }
}
